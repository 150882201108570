import service from "./axios";
import {useRouter} from "vue-router";


export const getPageTime = async () => {
  return await service.post('/dwart/data_screen_manager/dataBoard/queryDataBoardAllocation', {})
}

export const getPageUrl = (name) => {
  switch (name) {
    case '科目':
      return '/subjects'
    case '知识图谱':
      return '/know'
    case "ATS功能":
      return '/affects';
    case "学生情况":
      return '/student'
    case "课题研发":
      return '/rd';
    case "主屏":
      return '/map';
    case '师资力量':
      return '/teacher'
    default:
      return '/Notfound'
  }
}


export const changePage = async (history, $name) => {
  const data = await getPageTime();
  let index = data.contents.findIndex(v => v.name === $name)
  let nextObj;

  let showData = data.contents.filter(v=> v.isShow)
  for (let i = (index + 1); i < data.contents.length; i++) {
    if (data.contents[i].isShow){
       nextObj = data.contents[i];
       break;
    }
  }

  if (!nextObj){
    nextObj = showData[0];
  }

  let nextRoute = getPageUrl(nextObj.name);
  let timeout = setTimeout(() => {
    history.push(nextRoute);
  }, data.contents[index].showTime * 1000)
  return timeout;
}
